import { DependencyList, useEffect, useState } from 'react';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { cancelSubscription } from 'app/util/util';

export const useSubscription = <T>(observable: Observable<T>, callback: (T) => any, deps: DependencyList, onError?: (error: any) => any) => {
  const [subscription, setSubscription] = useState<Subscription>(null);
  useEffect(() => {
    cancelSubscription(subscription);

    if (!observable) {
      return;
    }

    const latestSubscription = observable.subscribe(callback, onError);
    setSubscription(latestSubscription);

    return () => cancelSubscription(latestSubscription);
  }, deps);
};
